<template>
    <Header/>
    <div v-if="order != {}" class="container is-max-widescreen mod-ribbon">
        <div class="columns">
            <div class="column">
                <div v-if="order.status == undefined">
                  <h1>Henter ordre...</h1>
                </div>

                <div v-if="order.status == 'paid'">
                  <h1>Velkommen til medvirke.dk!</h1>
                  <p>Din betaling er gået igennem, og <a href="#" target="_blank">din profil</a> er nu online og betalt – <a :href="[apiRoot] + ['/download?folder=invoices&id='] + order.id + ['&hash='] + [currentRoute.params.orderHash]" target="_blank">hent din faktura</a>.</p>
                  <p>Vi har sendt dig en kvittering på e-mail <strong>{{ order.invoiceMailedTo }}</strong> med flere informationer.</p>
                </div>

                <div v-if="order.status != undefined && order.status != 'paid'">
                  <h1>Hov! Noget gik galt med din betaling</h1>
                  <p>Din profil på medvirke.dk (ordrenummer {{ order.id }}) er næsten oprettet. Men betalingen er ikke blevet gennemført. Gennemfør venligst disse to handlinger:</p>

                  <ol>
                    <li>Måske mangler medvirke.dk informationer fra betalingssystemet. <a href="#" @click="reloadPage()">Klik og opdater din betaling</a></li>
                    <li>Er betalingen fortsat ikke er gået igennem? <a :href="order.paymentUrl">Klik og gå til betaling</a></li>
                  </ol>

                  <p>Hvis du fortsat har problemer med din betaling, så skriv til <a :href="['mailto:betaling@medvirke.dk?subject=Problemer med betaling&body=%0D%0A%0D%0A%0D%0AInfo om ordre:%0D%0AOrdrenummer: '] + [order.id] + ['%0D%0ABetalingslink: '] + [order.paymentUrl]" target="_blank">betaling@medvirke.dk</a>.</p>

                  <p v-if="cancelled == 1">Vi har også sendt dig en mail med dette link, så du kan betale, når det passer dig bedst.</p>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import ax from 'axios'
export const axios = ax
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    data() {
        return {
          order: {},
          referrer: document.referrer,
          cancelled: 0,
          apiRoot: process.env.VUE_APP_API_ROOT
        }
    },
    components: {
      Header,
      Footer
    },
    computed: {
      currentRoute() {
          return this.$route;
      }
    },
    created() {
      this.cancelled = parseInt(getParameterByName('cancelled'));
      this.getOrder();
    },
    methods: {
      getOrder() {
        // When payment is just made there can be delay on the QuickPay callback. Therefore wait a bit with loading data about payment from database
        let waitTime = this.referrer == 'https://payment.quickpay.net/' ? 1000 : 0;

        setTimeout(function(scope) { 

          let orderId = parseInt(scope.$route.params.orderId);
          let orderHash = scope.$route.params.orderHash;

          axios.get(process.env.VUE_APP_API_ROOT + '/order?id=' + orderId + '&hash=' + orderHash)
            .then(response => {
                scope.order = response.data.order;
                scope.orderLines = response.data.orderLines;
                scope.company = response.data.company;
            })  
        }, waitTime, this);
      },
      formatISOdateToFriendly(ISOdate) {
          // Takes yyyy-mm-dd and formats it like dd.mm.yyyy
          let day = ISOdate.substr(8,2);
          let month = ISOdate.substr(5,2);
          let year = ISOdate.substr(0,4);
          return day + '.' + month + '.' + year;
      },
      reloadPage() {
        location.reload();
      }
    }
})
</script>
